.App {
  background-color: #3e595e;
  max-width: 100vw;
  min-height: 92vh;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;  
}


.App-header {
  max-width: 100vw;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  font-family: notoserif;
  color: #c4dbdf;
}

.introMain {
  color: #fff;
  background-color: #3e595e;
  height: 12vh;
  width: 98vw;
  padding: 1vh 1vw 1vh 1vw;
  display: flex;
  padding-top: 16vh;
  position: fixed;
  z-index: 100;
}

.introMain a {
  width: 98vw;
  height: 8vh;
  display: flex;
  margin: 0.5rem;
  border-color: #282c34;
  border-width: 2px;
  border-style: outset;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.introMain a:hover {
  background-color: #282c34;
  border-style: inset;
}

.CoreCompetenciesTitle {
  width: 94vw;
  height: 8vh;
  margin: 0 3vw 0 2.5vw;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 30vh;
  font-size: 2em;
  text-decoration: underline;
  position: fixed;
  z-index: 1;
  background-color: #354e53;
  padding-bottom: 2vh;
}

.CoreCompetencies {
  display: flex;
  width: 98vw;
  font-size: 1em;
  padding-top: 6vh;
}

.CoreCompetenciesBox1 {
  display: block;
  margin: 0 0 0 2.75vw;
}
.CoreCompetenciesBox2 {
  display: block;
  margin: 0 0 0 0.25vw;
}

.CoreCompetenciesBox {
  width: 46vw;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5vh;
  font-size: 1em;
}

.CoreCompetenciesBoxDouble {
  width: 46vw;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5vh;
  font-size: 1em;
}

.intro {
  color: #fff;
  width: 92vw;
  margin-left: 4vw;
  margin-top: 48vmin;
  padding-top: 2vh;
  font-size: 1.25em;
}


.App-header a:link{
  color: #fff;
  font-size: 1em;
  text-decoration: none;
}

.App-header a:visited{
  color: #fff;
  text-decoration: none;
}

.App-header a:hover{
  color: #71a2aa;
  text-decoration: none;
}

.App-header a:active{
  color: greenyellow;
  text-decoration: none;
}

.NavbarLogointroMain a {
  width: 19vw;
  height: 18vmin;
  margin-top: 1vh;
  display: block;
  text-justify: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #282c34);
  border-radius: 20px;
}

.NavbarLogointroMain a div {
  margin-top: 4vh;
}

.NavbarLogointroMain a:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000);
}

.NavbarLogoApp {
  display: static;
  width: 12vmin;
  margin-top: 8vmin;
}

.App-logoApp {
  height: inherit;
  align-content: center;
  align-items: center;
  z-index: -100;
  column-count: 2;
  width: 20vmin;
}

.ButtonsWrapperApp {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2vh;
}

.MeApp {
  color: #ffffff;
  text-decoration: none;
  bottom: 0%;
}

/*For extra wide screens*/
@media screen and (min-width: 2000px) {

  .introMain {
    height: 8vh;
  } 

  .introMain a {
    line-height: 8vh;
  }

  .CoreCompetenciesTitle {
    font-size: 2.5em;
  }

  .intro {
    font-size: 2.25em;
  }

}


/*For Tablet screens*/
@media screen and (min-width: 1200px) {

  .App {
    min-height: 90vh;
  }

  .introMain {
    margin-top: 8vh;
    padding-top: 6vh;
    padding-bottom: 6vh;
    height: 8vh;
  }

  .App-header a:link{
    font-size: 1.75em;
    text-decoration: none;
  }

  .CoreCompetenciesTitle {
    padding-top: 20vh;
    margin-top: 10vh;
  }

  .intro {
    width: 92vw;
    margin-top: 40vh;
    margin-left: 3vw;
  }

}

/*For Mobile screens*/
@media screen and (max-width: 1000px) {

  .introMain {
    margin-top: 0vh;
    padding-top: 6vh;
    padding-bottom: 6vh;
    height: 8vh;
  }

  .App-header a:link{
    font-size: 2em;
    text-decoration: none;
  }

  .CoreCompetenciesTitle {
    padding-top: 22vh;
    padding-bottom: 0;
    font-size: 6em;
  }

  .CoreCompetencies {
    font-size: 2em;
    padding-top: 2vh;
  }

  .CoreCompetenciesBox {
    font-size: 1.8em;
    margin-top: 6vh;
    width: 40vw;
  }
  .CoreCompetenciesBoxDouble {
    font-size: 1.8em;
    margin-top: 6vh;
    width: 40vw;
  }

  .intro {
    font-size: 4em;
    margin-top: 30vh;
  }

}


@media (prefers-reduced-motion: no-preference) {
  .App-logoApp {
    animation: App-logo-spin infinite 18s linear;
    margin-top: 2vmin;
  }

  .CoreCompetencies {
    animation: fadeIn ease-in 2s;
  }
}

@keyframes App-logoApp-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


