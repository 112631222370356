.Certifications {
  background-color: #3e595e;
  top: 0;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  padding-top: 10vh;
}

.Certifications-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10vmin;
  color: white;
  margin-right: 16vmin;
}

.CertificationsTitle {
  min-width: 93vw;
  padding-top: 10vh;
  top: 0;
  font-size: 6vmin;
  background-color: #3e595e;
  text-align: center;
  position: fixed;
}

.CertificationsHeadline{
  font-size: 6vmin;
  text-align: left;
  margin-left: 2vmin;
  margin-right: 16vmin;
}

.CertificationsHeadline a {
  text-decoration: none;
  color: inherit;
}

.CertificationsHeadline a:hover {
  text-decoration: none;
  color: #71a2aa;
}

.CertificationsCompany{
  font-size: 4vmin;
  font-style: italic;
  text-align: left;
  margin-left: 2vmin;
  margin-top: -2vmin;
  margin-right: 16vmin;
}  

.CertificationsSpecial{
  font-size: 3vmin;
  font-style: italic;
  text-align: left;
  margin-left: 2vmin;
  margin-top: -2vmin;
  animation: fadeIn ease-in 2s;
} 

.CertificationsDate{
  font-size: 3.5vmin;
  text-align: left;
  margin-left: 2vmin;
} 

.CertificationsDetails{
  font-size: 4vmin;
  text-align: left;
  margin-left: 4vmin;
  margin-right: 16vmin;
  overflow-wrap: normal;
}   

#certificationsTitle {
  text-decoration: underline;
}

 /* Mobile */
 @media screen and (max-width: 800px) { 
  .Certifications {
    max-height: fit-contents
  }

  .CertificationsTitle {
    padding-top: 8vh;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}