.Hobbies {
  background-color: #3e595e;
  top: 0;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;

}

.Hobbies-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10vmin;
  color: white;
  margin-right: 16vmin;
}

.HobbiesTitle{
  margin-top: 10vmin;
  flex: 1;
  font-size: 8vmin;
  text-align: center;
  margin-left: -20vmin;
}

.HobbiesHeadline{
  font-size: 6vmin;
  text-align: left;
  margin-left: 2vmin;
}

.HobbiesCompany{
  font-size: 4vmin;
  font-style: italic;
  text-align: left;
  margin-left: 2vmin;
  margin-top: -2vmin;
}  

.HobbiesDate{
  font-size: 3.5vmin;
  text-align: left;
  margin-left: 2vmin;
} 

.HobbiesDetails {
  font-size: 4vmin;
  text-align: left;
  margin-top: -2vmin;
  margin-left: 4vmin;
  margin-right: 20vmin;
  overflow-wrap: normal;
}  

.HobbiesDetails img{
  height: 80px;
  width: 80px;
}

.LargeImageSectionContainer {
  display: flex;
  width: 98vw;
}

.LargeImageContainer {
  display: block;
  margin: 2vmin;
}

.Donut{
  width: 270px;
  height: 360px;
}  

.Restoration {
  height: 360px;
} 

.Trivia {
  width: 75vmin;
  height: 60vmin;
} 

.Pokemon {
  width: 60vmin;
  height: 60vmin;
}

.Soundcloud {
  width: 60vmin;
  margin: 2vmin;
}

.SoundcloudDetails {
  font-size: 4vmin;
  text-align: left;
  margin-top: -2vmin;
  margin-left: 4vmin;
  margin-right: 20vmin;
  overflow-wrap: normal;
} 

.HobbiesDetails iframe{
  background-color: #282c34;
  color: #282c34;
}

.HobbiesDetails a:link{
  color: #fff;
  font-size: 4vmin;
  text-decoration: none;
}

.HobbiesDetails a:visited{
  color: #fff;
  text-decoration: none;
}

.HobbiesDetails a:hover{
  color:teal;
  text-decoration: none;
}

.HobbiesDetails a:active{
  color: greenyellow;
  text-decoration: none;
}

@media screen and (min-width: 1450px) {

  .HobbiesDetails img{
    height: 160px;
    width: 160px;
  }

  .Donut {
    width: 540px;
    height: 720px;
  }

  .Restoration {
    height: 720px;
  }

  .Soundcloud {
    height: 18vh;
  }

  .SoundcloudDetails {
    width: 98vw;
  }

}

@media screen and (max-width: 1776px){

  .LargeImageSectionContainer {
    display: block;
  } 

  .Restoration {
    height: 180px;
  }

}

@media screen and (max-width: 800px) {

  .LargeImageSectionContainer {
    margin-left: -8vw;
  }

}