.Footer {
    max-width: 100vw;
    height: 16vh;
    overflow: hidden; 
    display: flex;
  }

  .FooterContainer {
    background-color: #3e595e;
    height: 16vh;
    min-width: 100vw;
    display: flex;
    color: white; 
    justify-content: space-evenly;
  }

  .FooterButtonContainer, .FooterButtonContainerLogo {
      width: 18vw;
      color: #e0479b;
      background-image: linear-gradient(rgba(0, 0, 0, 0), #282c34);
      border-radius: 20px;
      display: block;
      text-align: center;
  }

  .FooterButtonContainer:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), #000);
  }

  .FooterText {
    width: 18vw;
    text-align: center;
    height: 12vh;
    padding-left: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .App-logo {
      width: 16vw;
      height: 6vh;
      padding-top: 4vh;
      padding-bottom: 4vh;
      overflow: hidden;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .FooterButtonText {
    width: 16vw;
    height: 16vh;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Me {
    color: #fff;
    text-decoration: none;
  }

  .FooterText {
    height: 3vh;
    font-size: 2vmin;
  }

/* Mobile */
@media screen and (max-width: 800px){ 

  .FooterButtonContainer{
    margin: 0.25vw;
  }
  .FooterText {
    font-size: 2.25vmin;
  }

}

/* Wide */
@media screen and (min-width: 2000px) { 
  .FooterText {
    font-size: 3vmin;
  }
}
  
  