.Navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 10vmin;
    background:#282c34;
    z-index: 10000;
}
.CNLogo {
    position: absolute;
    height: 7vmin;
    width: 7vmin;
    top: 1vmin;
    left: 2vmin;
    align-items: left;
    z-index: 1000;
}
.NavbarName{
    padding-left: 10px;
    top: 0;
    text-align: center;
    font-family: notoserif;
    }  
.NavbarName a:link{
    color: #fff;
    font-size: 5vmin;
    text-decoration: none;
    padding-left: 10px;
  }
  
  .NavbarName a:visited{
    color: #fff;
    text-decoration: none;
  }
  
  .NavbarName a:hover{
    color:teal;
    text-decoration: none;
  }
  
  .NavbarName a:active{
    color: greenyellow;
    text-decoration: none;
  }  

.list {
    list-style-type: none;
    background: linear-gradient(#282c34, #3e595e);
    top: -2vmin;
    height: 8vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.items {
    margin-right: 20px;
    color: #f1f1f1;
    cursor: pointer;
    border-color: #282c34;
    border-width: 2px;
    border-style: outset;
    padding: 0.5rem;
    border-radius: 20px;
}

/* Link formatting*/
.list a:link{
    color: #fff;
    font-size: 3.75vmin;
    text-decoration: none;
  }
  
  .list a:visited{
    color: #fff;
    text-decoration: none;
  }
  
  .list a:hover{
    color:teal;
    background-color: #282c34;
    border-style: inset;
  }
  
  .list a:active{
    color: greenyellow;
    text-decoration: none;
  }


.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 1vmin;
    color: #fff;
    background-color: #282c34;
    font-size: 5vmin;
}

/*collapses List on mobile*/
@media screen and (max-width: 900px){
    .Navbar {
        background: linear-gradient(#282c34 65%, #3e595e);
        height: 10vmin;
    }
    .list {
        flex-direction: column;
        height: auto;
        top: -3vmin;
        background: linear-gradient(#3e595e, #282c34 5%, #3e595e);
    }
    .items:nth-child(n+3){
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        margin-top: 1vmin;
    }
    .items {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        text-align: left;
        margin-right: 0px;
        padding: 20px 0;
    }
    .items.a {
        width: 100%;
        text-align: left;
        margin-right: 0px;
        padding: 20px 0;
        font-size: 3vmin;
        color: aqua;
    }
    .btn {
        display: block;
    }
}


