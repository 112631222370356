.Work {
    background-color: #3e595e;
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    padding-top: 10vh;
  }

  .Work-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10vmin;
    color: white;
  }

  .WorkTitle{
    min-width: 93vw;
    padding-top: 16vh;
    top: 0;
    font-size: 6vmin;
    background-color: #3e595e;
    text-align: center;
    position: fixed;
  }

  .WorkHeadline{
    font-size: 5.5vmin;
    text-align: left;
    margin-left: 2vmin;
  }

  .WorkCompany{
    font-size: 3.75vmin;
    font-style: italic;
    text-align: left;
    margin-left: 2vmin;
    margin-top: -2vmin;
  }  

  .WorkDate{
    font-size: 3.25vmin;
    text-align: left;
    margin-left: 2vmin;
  } 

  .WorkDetails{
    font-size: 3.75vmin;
    text-align: left;
    margin-left: 4vmin;
    margin-right: 20vmin;
    overflow-wrap: normal;
    animation: fadeIn ease-in 2s;
  }   

  /* Mobile */
  @media screen and (max-width: 800px) { 

    .WorkTitle {
      padding-top: 8vh;
    }

    .WorkHeadline{
      margin-top: 4vh;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  